<template>
   <div>
      <div class="container">
         <table class="mt-4">
         <tbody>
         <template v-for="message in messages">
            <tr :key="message.id"  
               class="row message-row">

               <td class="p-3">{{ (new Date(message.created)).toLocaleDateString('en-US') }} </td>
               <td class="p-3">{{ (new Date(message.created)).toLocaleTimeString('en-US') }} </td>
               
            </tr>
            <tr :key="message.id"
               class="row">

               <td class="p-3" style="white-space: pre-line"><span v-html="message.message" /></td>
            </tr>
         </template>
         </tbody>
         </table>
      </div>
   </div>
</template>

<script>
export default {
   name: "Account",
   data() {
      return {
         messages: [],
      }
   },
   mounted() {
      window.gtag("event", "MessagesPage");
      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.messages = response.data.messages;
         })
         .catch((error) => console.error(error));
   },
   methods: {
   }
}
</script>

